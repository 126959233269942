<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 py-0"
      >
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" class="pb-1" />
    <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" outlined>
      <v-container class="pt-0">
        <v-row justify="end">
          <v-col cols="2" align-self="center" align="center" class="pr-0 pl-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                  @click.stop="toggleFiltersSelected"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pt-0 pb-0" @click="showFilters = true">
            <FiltersSelected :filters="filtersApplied" v-if="!showFilters" />
          </v-col>
        </v-row>
        <v-expand-transition mode="out-in">
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="applyFilters()"
          >
            <!-- Filtros comunes -->
            <v-row>
              <!-- Tipo de comprobante -->
              <v-col cols="12" md="4" class="py-0">
                <v-autocomplete
                  v-model="tipoComprobanteSelected"
                  return-object
                  :items="tiposComprobantes"
                  :rules="rules.required"
                  label="Tipo de comprobante (*)"
                  item-text="value"
                  item-value="id"
                  outlined
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
              <!-- Concepto -->
              <v-col cols="12" md="4" class="py-0">
                <v-autocomplete
                  v-model="conceptoSelected"
                  return-object
                  :items="conceptos"
                  label="Concepto"
                  item-text="value"
                  item-value="id"
                  outlined
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
              <!-- Forma de pago -->
              <v-col cols="12" md="4" class="py-0">
                <v-autocomplete
                  v-model="formaPagoSelected"
                  :items="formasPago"
                  return-object
                  label="Forma de pago"
                  item-text="value"
                  item-value="id"
                  outlined
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
              <!-- Importe desde -->
              <v-col cols="12" md="4" class="py-0">
                <currency-input
                  v-model="importeDesde"
                  label="Importe desde (*)"
                  :options="currencyOptions"
                  :rules="
                    rules.required.concat(
                      rules.validDateRange(importeDesde, importeHasta)
                    )
                  "
                ></currency-input>
              </v-col>
              <!-- Importe hasta -->
              <v-col cols="12" md="4" class="py-0">
                <currency-input
                  v-model="importeHasta"
                  label="Importe hasta (*)"
                  :options="currencyOptions"
                  :rules="
                    rules.required.concat(
                      rules.validDateRange(importeDesde, importeHasta)
                    )
                  "
                ></currency-input>
              </v-col>
              <!-- Vigencia -->
              <v-col cols="12" md="4" class="py-0">
                <v-menu
                  ref="vigencia"
                  v-model="menuVigencia"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="vigenteAlFormat"
                      label="Vigencia (*)"
                      :append-icon="calendarIcon"
                      v-mask="'##/##/####'"
                      hint="Formato DD/MM/AAAA"
                      @blur="vigenteAl = parseDateToIso(vigenteAlFormat)"
                      dense
                      outlined
                      autocomplete="off"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vigenteAl"
                    @change="vigenteAlFormat = formatDate(vigenteAl)"
                    no-title
                    scrollable
                    @input="menuVigencia = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="pr-4 pl-4" justify="end">
              <v-col cols="12" md="8" class="py-0" align="left">
                <v-subheader>(*) Datos obligatorios</v-subheader>
              </v-col>
              <v-col cols="12" md="4" align="end" class="text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      size="28"
                      @click="resetForm"
                    >
                      {{ clearFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>Limpiar filtros</span>
                </v-tooltip>
                <v-btn
                  color="primary"
                  elevation="2"
                  small
                  :disabled="!isFormValid"
                  type="submit"
                  form="filters-form"
                >
                  Aplicar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <v-card>
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :headers="headers"
            item-key="devAdiExtraId"
            :items="items"
            :loading="loading"
            :search="search"
            show-select
            v-model="registrosSeleccionados"
            class="elevation-1"
            loading-text="Cargando datos..."
            @toggle-select-all="toggleSelectedItems()"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="6" align-self="center">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.tieneIva`]="{ item }">
              <v-icon v-if="item.tieneIva" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-form
      v-model="actualizacionValida"
      ref="actualizacion-form"
      id="actualizacion-form"
      @submit.prevent="nuevoProcesoActualizacion()"
    >
      <v-card :loading="loadingProceso">
          <template slot="progress">
            <v-progress-linear
              color="primary"
              indeterminate
            ></v-progress-linear>
          </template>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-card-title class="primary--text pb-0">
              Datos para el aumento</v-card-title
            >
          </v-col>
          <!-- Período desde -->
          <v-col cols="12" md="3" class="pl-8 pb-0 pt-7">
            <v-text-field
              v-model="periodoDesde"
              label="Período desde"
              outlined
              dense
              v-mask="'######'"
              :rules="
                rules.required.concat(
                  rules.periodoYyyyMm.concat(
                    rules.validDateRange(periodoDesde, periodoHasta)
                  )
                )
              "
              hint="Formato AAAAMM"
              persistent-hint
            ></v-text-field>
          </v-col>

          <!-- Período hasta -->
          <v-col cols="12" md="3" class="pb-0 pt-7">
            <v-text-field
              v-model="periodoHasta"
              label="Período hasta"
              outlined
              dense
              v-mask="'######'"
              :rules="
                rules.required.concat(
                  rules.periodoYyyyMm.concat(
                    rules.validDateRange(periodoDesde, periodoHasta)
                  )
                )
              "
              hint="Formato AAAAMM"
              persistent-hint
            ></v-text-field>
          </v-col>

          <v-col cols="2" class="py-0 mt-0">
            <v-radio-group v-model="modoAumento" mandatory class="py-0">
              <v-radio class="py-0" label="Importe" value="I"></v-radio>
              <v-radio class="py-0" label="Porcentaje" value="P"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="3" class="pb-0 pt-7">
            <currency-input
              v-model="valorAumento"
              label="Valor"
              :options="modoAumento == 'P' ? percentOptions : currencyOptions"
              :rules="rules.required"
              :appendIcon="modoAumento == 'I' ? '$' : '%'"
            ></currency-input>
          </v-col>
          <v-col cols="12" class=" pt-0 pb-2 pr-8 text-right">
            <v-btn
              color="primary"
              :disabled="!actualizacionValida || loadingProceso"
              elevation="2"
              type="submit"
              form="actualizacion-form"
            >
              Procesar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
     </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="modalVerDetalleProcesoActualizacion"
      v-if="modalVerDetalleProcesoActualizacion"
      @keydown.esc="backToProcesoActualizacion()"
      max-width="90%"
      persistent
    >
      <VerDetalleActualizacionAdiExtra
        :aumentoId="aumentoId"
        @toggleModalSeeProcesoActualizacion="backToProcesoActualizacion()"
      ></VerDetalleActualizacionAdiExtra>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import FiltersSelected from "@/components/shared/FiltersSelected";
import helpersExcel from "@/utils/helpers/importExportExcel";
import Ayuda from "@/components/shared/Ayuda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import VerDetalleActualizacionAdiExtra from "@/components/modules/cuotas/devengamientos/VerDetalleActualizacionAdiExtra.vue";

export default {
  name: "NuevoProcesoActualizacionAdicionales",
  components: {
    Ayuda,
    PageHeader,
    FiltersSelected,
    CurrencyInput,
    VerDetalleActualizacionAdiExtra,
    GoBackBtn
  },
  directives: { mask },
  data: vm => ({
    title: enums.titles.NUEVO_ACTUALIZACION_ADICIONALES_EXTRA,
    verDetalleActualizacionTitle: "Detalle del proceso de actualizacion",
    calendarIcon: enums.icons.CALENDAR,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    checkIcon: enums.icons.CHECK_OUTLINE,
    searchIcon: enums.icons.SEARCH,
    search: "",
    showExpand: false,
    showHelp: false,
    optionCode: enums.webSiteOptions.NUEVA_ACTUALIZACION_ADICIONALES_EXTRA,
    showIcon: true,
    routeToGo: "ProcesoActualizacionAdicionales",
    rules: rules,
    showFilters: true,
    actualizacionValida: false,
    loadingProceso: false,
    filtersApplied: [],
    isFormValid: false,
    vigenteAl: new Date().toISOString().substr(0, 10),
    vigenteAlFormat: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menuVigencia: null,
    loading: false,
    aumentoId: null,
    tiposComprobantes: [],
    tipoComprobanteSelected: null,
    conceptos: [],
    conceptoSelected: null,
    formasPago: [],
    formaPagoSelected: null,
    importeDesde: 0,
    importeHasta: 0,
    periodoDesde: null,
    periodoHasta: null,
    modoAumento: null,
    valorAumento: null,
    items: [],
    resultadoActualizacionItems: [],
    registrosSeleccionados: [],
    modalVerDetalleProcesoActualizacion: false,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    percentOptions: {
      locale: "es-ES",
      currency: "USD",
      currencyDisplay: "hidden",
      valueRange: {
        min: -99.99,
        max: 10000
      },
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      useGrouping: false,
      accountingSign: false
    },
    headers: [
      {
        text: "Agente de cuenta",
        value: "agectaNom",
        align: "start",
        sortable: false
      },
      {
        text: "Tipo de comprobante",
        value: "comprobante",
        sortable: false,
        align: "start"
      },
      {
        text: "Concepto",
        value: "concepto",
        align: "start",
        sortable: false
      },
      {
        text: "Forma de pago",
        value: "formaPago.value",
        align: "start",
        sortable: false
      },
      {
        text: "Tiene IVA",
        value: "tieneIva",
        align: "center",
        sortable: false
      },
      {
        text: "Importe",
        value: "importeString",
        sortable: false,
        align: "end"
      },
      {
        text: "Período desde",
        value: "periodoDesde",
        align: "end",
        sortable: false
      },
      {
        text: "Período hasta",
        value: "periodoHasta",
        align: "end",
        sortable: false
      }
    ]
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setSelects();
  },

  methods: {
    ...mapActions({
      getTiposComprobantesDeudaIngreso:
        "afiliaciones/getTiposComprobantesDeudaIngreso",
      getConceptos: "devengamientos/getConceptos",
      getAdicionalesExtra: "devengamientos/getAdicionalesExtra",
      nuevoProcesoAumentoAdicionales:
        "devengamientos/nuevoProcesoAumentoAdicionales",
      getFormasDePagoPideEntFin: "devengamientos/getFormasDePagoPideEntFin",
      setAlert: "user/setAlert"
    }),

    async setSelects() {
      const tiposComprobantesReponse = await this.getTiposComprobantesDeudaIngreso();
      this.tiposComprobantes = tiposComprobantesReponse;
      const conceptosResponse = await this.getConceptos();
      this.conceptos = conceptosResponse;
      const formasPagoResponse = await this.getFormasDePagoPideEntFin();
      this.formasPago = formasPagoResponse;
    },

    exportExcel() {
      let result = [];
      this.resultadoActualizacionItems.forEach(x =>
        result.push({
          ["Agente de cuenta"]: x.agectaNom,
          ["Comprobante"]: x.comprobante,
          ["Concepto"]: x.concepto,
          ["Forma de pago"]: x.formaPago.value,
          ["Importe total"]: x.importe,
          ["Periodo desde"]: x.periodoDesde,
          ["Periodo Hasta"]: x.periodoHasta,
          ["IVA incluido"]: x.tieneIva ? "SI" : "NO"
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Detalle adicionales");
    },

    async nuevoProcesoActualizacion() {
      if (!this.registrosSeleccionados.length > 0) {
        this.setAlert({
          type: "warning",
          message: "Debe seleccionar al menos un adicional para actualizar"
        });
      } else {
        this.loadingProceso = true;
        const data = {
          adicionalesExtra: this.registrosSeleccionados,
          modo: this.modoAumento,
          periodoDesde: this.periodoDesde,
          periodoHasta: this.periodoHasta,
          valor: this.valorAumento,
          importeDesde: this.importeDesde,
          importeHasta: this.importeHasta,
          tCompId: this.tipoComprobanteSelected.id,
          vigencia: this.vigenteAl
        };
        try {
          const response = await this.nuevoProcesoAumentoAdicionales(data);
          this.resultadoActualizacionItems = response;
          this.aumentoId = response.devAdiExtraAumentoId.devAdiExtraAumentoId;
          if (response.status === 200)
            await this.setAlert({
              type: "success",
              message: "Guardado con éxito"
            });

          this.toggleModalSeeProcesoActualizacion();
        } catch (exc) {}
        this.loadingProceso = false;
      }
    },
    // metodos de filtros
    async applyFilters() {
      this.loading = true;
      this.toggleFiltersSelected();

      let fechaToArray = this.vigenteAl.split("-");
      let arrayToPeriodo = fechaToArray[0] + fechaToArray[1];

      const filters = {
        TCompId: this.tipoComprobanteSelected.id,
        ConceptoId: this.conceptoSelected ? this.conceptoSelected.id : null,
        FPagoId: this.formaPagoSelected ? this.formaPagoSelected.id : null,
        ImporteDesde: this.importeDesde,
        ImporteHasta: this.importeHasta,
        VigenteAl: arrayToPeriodo
      };
      try {
        const response = await this.getAdicionalesExtra(filters);
        this.items = response;
        this.registrosSeleccionados = this.items;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];

      if (this.tipoComprobanteSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "tipoComprobanteSelected",
          label: "Tipo de comprobante",
          model: this.tipoComprobanteSelected.value
        });
      }
      if (this.conceptoSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "conceptoSelected",
          label: "Concepto",
          model: this.conceptoSelected.value
        });
      }
      if (this.formaPagoSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "formaPagoSelected",
          label: "Forma de pago",
          model: this.formaPagoSelected.value
        });
      }
      if (this.importeDesde >= 0) {
        this.filtersApplied.splice(3, 1, {
          key: "importeDesde",
          label: "Importe desde",
          model: this.importeDesde.toString()
        });
      }
      if (this.importeHasta >= 0) {
        this.filtersApplied.splice(4, 1, {
          key: "importeHasta",
          label: "Importe hasta",
          model: this.importeHasta.toString()
        });
      }
      if (this.vigenteAl) {
        this.filtersApplied.splice(5, 1, {
          key: "vigenteAl",
          label: "Vigente al",
          model: this.vigenteAlFormat
        });
      }
    },
    resetForm() {
      this.tipoComprobanteSelected = null;
      this.formaPagoSelected = null;
      this.conceptoSelected = null;
      this.importeDesde = null;
      this.importeHasta = null;
      this.vigenteAlFormat = null;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    // toggles
    toggleSelectedItems() {
      this.registrosSeleccionados.length < this.items.length
        ? (this.registrosSeleccionados = this.items)
        : (this.registrosSeleccionados = []);
    },
    toggleModalSeeProcesoActualizacion() {
      this.modalVerDetalleProcesoActualizacion = !this
        .modalVerDetalleProcesoActualizacion;
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    backToProcesoActualizacion() {
      this.$router.push({ name: "ProcesoActualizacionAdicionales" });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -webkit-moz-appearance: textfield;
}
</style>
